import React, { useState } from 'react'
import { connect } from 'react-redux'
import { navigate } from 'gatsby'
import * as renaultProjectModeActionCreators from '../../../../state/actions'
import EditIcon from '../../../../../../assets/svg/edit-scoring-icon.svg'
import DeleteIcon from '../../../../../../assets/svg/trash.svg'
import Dialog from '../../Common/Dialog'

function ProjectCardFooter({ texts, project, projectUrl, deleteSearchedProject }) {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [confirmDelete, setConfirmDelete] = useState(false)

  const handleClickRemove = async () => {
    setOpenDeleteDialog(true)
    setConfirmDelete(false)
  }

  const handleClickDialogRemove = async () => {
    setConfirmDelete(true)
  }

  const handleClickConfirmRemove = async () => {
    deleteSearchedProject(project.id)
  }

  return (
    <>
      <div
        className="project-card__footer"
        onClick={e => {
          e.preventDefault()
          e.stopPropagation()
        }}
        style={{ cursor: 'auto', justifyContent: 'end' }}
        onKeyDown={() => {}}
        role="button"
        tabIndex={0}
      >
        <div className="project-card__edit-button" style={{ display: 'flex', gap: 15 }}>
          <div
            onClick={handleClickRemove}
            className="project-card__delete-button"
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
            style={{ cursor: 'pointer' }}
          >
            <DeleteIcon width="16" height="16" className="theme_svg" />
          </div>
          <div
            onClick={e => {
              e.preventDefault()
              e.stopPropagation()
              navigate(projectUrl)
            }}
            style={{ cursor: 'pointer' }}
            onKeyDown={() => {}}
            role="button"
            tabIndex={0}
          >
            <EditIcon width="16" height="16" className="theme_svg" />
          </div>
        </div>
      </div>
      <Dialog
        open={openDeleteDialog}
        setOpen={setOpenDeleteDialog}
        onConfirm={confirmDelete ? handleClickConfirmRemove : handleClickDialogRemove}
        closeOnConfirm={false}
        title={`${texts.delete}  ${project.name ?? texts.project_name}`}
        text={
          confirmDelete
            ? `${texts.are_you_really_sure_delete} ${project.name ?? texts.project_name}? ${
                texts.will_be_lost_permanently
              }.`
            : `${texts.are_you_sure_delete} ${project.name ?? texts.project_name}?`
        }
        textColor={confirmDelete && 'red'}
        confirmButtonText={texts.delete}
      />
    </>
  )
}

const mapStateToProps = state => {
  return {
    texts: state.texts.values,
  }
}

export default connect(mapStateToProps, renaultProjectModeActionCreators)(ProjectCardFooter)
